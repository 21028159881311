/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as S from './styles'
import { Card, IconBell, Toggle } from '@veneer/core'
import { useShellRootContext } from 'src/contexts/ShellRoot'
import ComponentLoader from '../../../../src/shared-Components/ComponentLoader/index'
import {
  UserPreferences,
  getPreferencesFromApi,
  updatePreferencesFromApi
} from '../../../../src/utils/api'
import {
  SUPPLIES_ALERT_EMAIL_TRUE,
  SUPPLIES_ALERT_EMAIL_FALSE
} from '../../../../src/utils/constants'

type PageBodyType = {
  mock?: boolean
}

const PageBody = ({ mock }: PageBodyType) => {
  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const authProvider = interfaces?.v2.authProvider || {}
  const authStack = interfaces?.v1?.app?.getAuthStack?.()
  const events = interfaces?.v1?.events
  // Component loader
  const [loader, setLoader] = useState<boolean>(true)

  // Handle Veneer Toggle On state
  const [toggleOn, setToggleOn] = useState<boolean>(true)

  // Get user preferences from API
  const getUserPreferences = useCallback(async () => {
    try {
      await getPreferencesFromApi(authStack, authProvider).then((response) => {
        setToggleOn(
          response.data.prefs.transactional['supplies-alert'].email.value
        )
      })
    } catch (e) {
      // do nothing
    }
  }, [authProvider, authStack])

  const loadData = useCallback(() => {
    setLoader(true)
    getUserPreferences()
    setTimeout(() => {
      setLoader(false)
    }, 1500)
  }, [getUserPreferences])

  // Call getUserPreferences once when component loads
  useEffect(() => {
    loadData()
  }, [loadData])

  // Update user preferences from API
  const updateUserPreferences = useCallback(
    async (options: UserPreferences) => {
      try {
        await updatePreferencesFromApi(authStack, authProvider, options)
      } catch (e) {
        // do nothing
      }
    },
    [authProvider, authStack]
  )

  // Handle onChange of Veneer Toggle
  const onChange = useCallback(() => {
    let options: UserPreferences
    /* istanbul ignore else */
    if (toggleOn) {
      setToggleOn(false)
      options = SUPPLIES_ALERT_EMAIL_FALSE
      updateUserPreferences(options)
    } else {
      setToggleOn(true)
      options = SUPPLIES_ALERT_EMAIL_TRUE
      updateUserPreferences(options)
    }
  }, [toggleOn, updateUserPreferences])

  useEffect(() => {
    events.addEventListener('ecp-banner-reload-call', loadData)
    return () => {
      events.removeEventListener('ecp-banner-reload-call', loadData)
    }
  }, [events, loadData])

  const mainContent = useMemo(
    () => (
      <S.Container
        className={'header-settings'}
        data-testid="notification-settings-container"
      >
        <S.CardStyle>
          <Card
            content={
              <S.InnerCardStyles>
                <S.NotificationSection>
                  <IconBell
                    size={24}
                    data-testid="bell-icon"
                  />
                  <S.NotificationFrame>
                    <S.SubTitle data-testid="all-notifications-title">
                      {t('notificationSettings.title', 'All notifications')}
                    </S.SubTitle>
                    <br></br>
                    <S.Description data-testid="notification-settings-description">
                      {t(
                        'notificationSettings.description',
                        'Set up the preferences of receiving notification on email. The email preference will only reflect in receiving device and fleet related notifications.'
                      )}
                    </S.Description>
                  </S.NotificationFrame>
                </S.NotificationSection>
                <S.EmailSection>
                  <S.Email data-testid="email">
                    {t('notificationSettings.email', 'Email')}
                  </S.Email>
                  <Toggle
                    data-testid="email-toggle-button"
                    id={'email-toggle'}
                    label={
                      toggleOn
                        ? `${t('notificationSettings.on', 'On')}`
                        : `${t('notificationSettings.off', 'Off')}`
                    }
                    on={toggleOn}
                    onChange={onChange}
                  />
                </S.EmailSection>
              </S.InnerCardStyles>
            }
          />
        </S.CardStyle>
        <S.CardBottomHr></S.CardBottomHr>
        <S.SubTitle data-testid="upcoming-update-title">
          {t(
            'notificationSettings.upcomingUpdate',
            'Upcoming features in the next update'
          )}
        </S.SubTitle>
        <br></br>
        <S.Description data-testid="update-description">
          {t(
            'notificationSettings.updateDescription',
            'IT admin will be able to set up the frequency and severity for each type of notifications you would like to receive.'
          )}
        </S.Description>
      </S.Container>
    ),
    [onChange, t, toggleOn]
  )

  const renderPageBody = useMemo(() => {
    if (mock) return mainContent
    if (loader) return <ComponentLoader />
    else return mainContent
  }, [mock, mainContent, loader])

  return <>{renderPageBody}</>
}

export default PageBody
