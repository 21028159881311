export const SUPPLIES_ALERT_EMAIL_TRUE = {
  type: 'transactional',
  subType: 'supplies-alert',
  transport: 'email',
  userPrefValue: true
}

export const SUPPLIES_ALERT_EMAIL_FALSE = {
  type: 'transactional',
  subType: 'supplies-alert',
  transport: 'email',
  userPrefValue: false
}
