import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const CardStyle = styled.div`
  padding: ${primitives.typography.size10} ${primitives.typography.size10}
    ${primitives.typography.size10} 0;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: base-line;
`

export const InnerCardStyles = styled.div`
  padding: ${primitives.layout.size6}px;
`

export const CardBottomHr = styled.div`
  height: 2px;
  position: relative;
  margin-right: 100px;
  margin-bottom: ${primitives.layout.size4}px;
  background-color: ${primitives.color.gray2};
`

export const NotificationSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const EmailSection = styled.div`
  display: flex;
  margin-top: ${primitives.layout.size5}px;
  #email-toggle-label {
    word-break: normal;
  }
`
export const Title = styled.div`
  font-size: ${primitives.typography.size3};
  line-height: ${primitives.typography.lineHeight2};
`
export const NotificationFrame = styled.div`
  margin-left: ${primitives.layout.size3}px;
`
export const Email = styled.span`
  margin-right: 10px;
`
export const SubTitle = styled.span`
  font-size: ${primitives.typography.size3};
  font-weight: 400;
  line-height: ${primitives.typography.lineHeight2};
  letter-spacing: 0em;
  text-align: left;
`

export const Description = styled.span`
  font-size: ${primitives.typography.size3};
  font-weight: 400;
  line-height: ${primitives.typography.lineHeight2};
  letter-spacing: 0.03em;
  text-align: left;
`
export const Container = styled.div`
  width: 100vw;
  max-width: 100%;
  padding-left: ${primitives.layout.size6}px;
  overflow-x: auto;
`
