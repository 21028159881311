import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 24px;
  div {
    width: 24px;
    height: 24px;
    margin: 0 auto;
    position: relative;
    top: 50%;
  }
`
