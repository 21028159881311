import React from 'react'
import NotificationSettings from '../components/NotificationSettings'
import { Container } from './styles'
import * as T from './types'

const App = ({ ...props }: T.AppPropsType) => {
  return (
    <Container>
      <NotificationSettings {...props} />
    </Container>
  )
}

export default App
