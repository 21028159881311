import React, { useCallback } from 'react'
import { RmcMfeLoader } from '@jarvis/react-mfe-component'

export const HeaderBanner = ({ title }) => {
  const SubMfe = useCallback(({ component, title, type }) => {
    return (
      <RmcMfeLoader
        disableContainerPadding
        {...{
          component,
          title,
          type
        }}
      />
    )
  }, [])
  return (
    <SubMfe
      type="headerTitle"
      title={title}
      component="@jarvis/react-ecp-header"
    />
  )
}
