import React from 'react'
import { HeaderBanner } from '../../shared-Components/Header'
import { useShellRootContext } from 'src/contexts/ShellRoot'
import PageBody from '../../../src/components/NotificationSettings/PageBody/index'

const NotificationSettings = () => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {}
  return (
    <>
      <HeaderBanner
        title={`${t('notificationSettings.header', 'Notification Settings')}`}
      />
      <PageBody />
    </>
  )
}

export default NotificationSettings
