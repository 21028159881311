/* eslint-disable @typescript-eslint/no-explicit-any */
import { MessageHubSvcClient } from '@jarvis/web-stratus-client'
export interface UserPreferences {
  type: string
  subType: string
  transport: string
  userPrefValue: boolean
}

export const getPreferencesFromApi: any = async (stack, auth) => {
  const client = new MessageHubSvcClient(stack, auth)
  const response = await client.getPreferences()
  return response
}

export const updatePreferencesFromApi: any = async (
  stack,
  auth,
  options: UserPreferences
) => {
  const client = new MessageHubSvcClient(stack, auth)
  const response = await client.updatePreferences(options)
  return response
}
